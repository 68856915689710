

  import MyCheckWorldCheckStatus from '~/components/marketplace/my_checks/MyCheckWorldCheckStatus.vue'
  import MyCheckCreditReformStatus from '~/components/marketplace/my_checks/MyCheckCreditReformStatus.vue'
  import MyCheckDefaultStatus from '~/components/marketplace/my_checks/MyCheckDefaultStatus.vue'
  import MyCheckIDStatus from '~/components/marketplace/my_checks/MyCheckIDStatus.vue'

  export default {
    name: `MyCheckStatusFactory`,
    components: {
      MyCheckWorldCheckStatus,
      MyCheckDefaultStatus,
      MyCheckIDStatus
    },
    data() {
      return {
        componentByType: {
          "world_check": MyCheckWorldCheckStatus,
          "credit_reform": MyCheckCreditReformStatus,
          "identt": MyCheckIDStatus,
          "default": MyCheckDefaultStatus
        }
      }
    },
    props: {
      myCheck: {
        type: Object,
        default: undefined
      },
    },
    computed: {
      component(){
        return Object.keys(this.componentByType).includes(this.myCheck?.type?.check_code)
          ? this.componentByType[this.myCheck.type?.check_code]
          : this.componentByType.default
      }
    }
  }
